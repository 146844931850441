import React from "react";
import {createUseStyles} from 'react-jss';
import {
    aboveBreakpoint,
    belowBreakpoint,
    blue, breakpoint4, breakpoint5,
    defaultHeadline,
    defaultPadding,
    defaultText, SourceSansProBold,
    SourceSansProRegular, SourceSansProSemiBold,
    white
} from "../mixins";
import health from "../images/Health.svg";
import computer from "../images/Computer.svg";

const useStyles = createUseStyles({
    healthSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'space-between'
    },
    text: {
        ...defaultText,
        marginBottom: '60px',
        ...belowBreakpoint(breakpoint5, {
            marginBottom: '0px',
        })
    },
    headline: {
        ...defaultHeadline,
        color: blue,
    },
    noSpacingText: {
        ...defaultText,
        marginTop: '0',
        marginBottom: '0 !important',
    },
    topSection: {
        maxWidth: '1024px',
        paddingTop: defaultPadding,
        paddingLeft: defaultPadding,
        paddingRight: defaultPadding,
        ...belowBreakpoint(breakpoint5, {
            padding: '30px',
        })
    },
    bottomSection: {
        width: '100%',
        backgroundColor: blue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonSectionLimit: {
        ...aboveBreakpoint(breakpoint4, {
            display: 'flex',
        }),
        maxWidth: '1024px',
    },
    innerBottomSection: {
        margin: '60px',
        maxWidth: '370px',
    },
    smallText: {
        color: white,
        fontFamily: SourceSansProRegular,
        fontsize: '18px',
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: '0.25px',
    },
    image: {
        height: '60px'
    },
    mediumBold: {
        fontFamily: SourceSansProSemiBold,
    },
    bold: {
        fontFamily: SourceSansProBold,
    }
});

const HealthSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.healthSection}>
            <div className={classes.topSection}>
                <p className={classes.noSpacingText}>WITHIN THESE OBJECTIVES, THE COVIT PLATFORM WAS BORN. WITH AN
                    ASPIRATION TO BE THE <span className={classes.bold}> LONGTERM AFFORDABLE AND GOLD-STANDARD FRAMEWORK </span> FOR ALL TELE-MEDICINE
                    RELATED CARE.</p>
                <p className={classes.headline}>A TELE-HEALTH & MEDICINE SYSTEM</p>
                <p className={classes.noSpacingText}><span className={classes.bold}> ENVISIONED by the people </span> willing to help and improve -
                </p>
                <p className={classes.noSpacingText}><span className={classes.bold}> DESIGNED by the people </span> with strategic special knowledge
                    and skills - </p>
                <p className={classes.noSpacingText}><span className={classes.bold}> DEVELOPED for the people </span> in need of care and professional
                    tools</p>
                <p className={classes.text}>Available to <span className={classes.bold}> all health systems, providers, clinics,hospitals and
                    patients for an affordable price </span> with unlimited maintenance and support.</p>
            </div>
            <div className={classes.bottomSection}>
                <div className={classes.buttonSectionLimit}>
                    <div className={classes.innerBottomSection}>
                        <img className={classes.image} src={health} alt="Health"/>
                        <p className={classes.smallText}><span className={classes.mediumBold}>Designed by advanced medical expertise </span> in
                            collaboration with clinical specialists, practice providers, nurses, respiratory therapists,
                            telemetry technicians and other medical practitioners from several health institutions in
                            Europe.</p>
                    </div>
                    <div className={classes.innerBottomSection}>
                        <img className={classes.image} src={computer} alt="Computer"/>
                        <p className={classes.smallText}><span className={classes.mediumBold}>Iterative - incremental agile development </span> managed
                            by a consortium of several private European initiative partners in the field of IT (medical
                            services, e-helth, mobile and embedded technologies) with contributions from an increasing
                            network of freelancers (developers, designers, QA, other supporters).</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HealthSection;
