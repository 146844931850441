import React, {useState} from "react";
import {createUseStyles} from 'react-jss';
import {
    belowBreakpoint, breakpoint2, breakpoint4,
    breakpoint5,
    darkRed,
    defaultHeadline,
    defaultPadding,
    defaultText,
    dynamicFontSize, SourceSansProBold,
    SourceSansProRegular,
    white
} from "../mixins";
// import EuropeanCommissionLogo from "../images/EuropeanCommissionLogo.svg";
// import ukc from "../images/UKCLogo.svg";
// import inova from "../images/InovaLogo.svg";
import ModalForm from "./ModalForm";
import Button from "./Button";

const useStyles = createUseStyles({
    supportSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    headline: {
        ...defaultHeadline,
        color: 'white',
        marginBottom: '20px',
        marginTop: '0'
    },
    text: {
        ...defaultText,
        color: white,
    },
    topSection: {
        padding: defaultPadding,
        ...belowBreakpoint(breakpoint5, {
            padding: '30px',
        }),
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '1100px',
    },
    bottomSection: {
        textAlign: 'center',
        backgroundColor: darkRed,
        width: '100%',
        marginTop: '20px',
    },
    smallText: {
        fontFamily: SourceSansProRegular,
        color: white,
        ...dynamicFontSize(14, 10, 10),
        lineHeight: '20px',
        letterSpacing: '0.5px',
    },
    supportedBy: {
        ...dynamicFontSize(14, 10, 10),
    },
    footerText: {
        fontFamily: SourceSansProRegular,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '1px',
        color: 'white',
        margin: '35px',
        ...belowBreakpoint(breakpoint2, {
            margin: '20px',
            fontSize: '14px',
        }),
        ...belowBreakpoint(breakpoint4, {
            margin: '10px',
            lineHeight: '18px',
            fontSize: '10px',
        }),
    },
    iconWrapper: {
        marginBottom: '60px',
        ...belowBreakpoint(breakpoint4, {
            marginBottom: '30px',
        })
    },
    icon: {
        marginRight: '20px',
        marginLeft: '20px'
    },
    donateButton: {
        height: '42px',
        width: '142px',
    },
    bold: {
        fontFamily: SourceSansProBold,
    }
});

const SupportSection = () => {
    const [modalOpened, setModalOpened] = useState(false);
    const classes = useStyles();
    return (
        <div className={classes.supportSection}>
            {modalOpened &&
            <ModalForm close={() => setModalOpened(false)}/>
            }
            <div className={classes.topSection}>
                <p className={classes.headline}>your support</p>
                <p className={classes.text}>All our efforts and results in the underlying technology and tools will
                    be <span className={classes.bold}> available to all health systems, providers, clinics and hospitals for an affordable price </span> with
                    unlimited maintenance and support.</p>
                <p className={classes.text}>The whole platform and its infrastructure is already under the GNU General
                    Public License.</p>

                <Button text={'join us now'} action={() => setModalOpened(true)}/>

                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                    <input type="hidden" name="cmd" value="_s-xclick" />
                    <input type="hidden" name="hosted_button_id" value="WPBEKJ3L6TQNL" />
                    <input className={classes.donateButton} type="image" src="https://covit.si/paypal.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://covit.si/paypal.png" width="1" height="1" />
                </form>
            </div>
            <div className={classes.bottomSection}>
                <p className={classes.footerText}>DESIGNED BY THE PEOPLE – FOR THE PEOPLE. FROM THE FIRST TO THE LAST
                    LINE OF DEFENSE.</p>
            </div>
        </div>
    )
};

export default SupportSection;
