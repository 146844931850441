import React, {useEffect, useRef} from 'react';
import $ from 'jquery';
import './Tick.scss'

const Tick = ({isLoading}) => {
    const ref = useRef();
    useEffect(() => {
        if (!isLoading)
            $('.checkmark').toggle();
    }, [isLoading]);
    return (
        <div className={`circle-loader ${isLoading ? "" : "load-complete"}`}>
            <div className="checkmark draw" ref={ref}/>
        </div>
    );
};
export default Tick;