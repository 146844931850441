import React from "react";
import {createUseStyles} from 'react-jss';
import BoxText from "./BoxText";
import {
    aboveBreakpoint, belowBreakpoint,
    breakpoint2,
    breakpoint5,
    defaultHeadline,
} from "../mixins";
import heart from "../images/Heart.svg";

const useStyles = createUseStyles({
    solutionSection: {
        maxWidth: '1048px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        ...defaultHeadline,
    },
    heart: {
        width: '100px',
        ...aboveBreakpoint(breakpoint2, {
            marginTop: '135px'
        }),
        ...aboveBreakpoint(breakpoint5, {
            marginTop: '70px'
        }),
        ...belowBreakpoint(breakpoint5, {
            marginTop: '10px',
            width: '80px',
        })

    }
});

const SolutionSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.solutionSection}>
            <BoxText text={'the solution'}/>
            <p className={classes.text}>REMOTE CARE AND TREATMENT OF INFECTED PATIENTS IS A VIABLE AND CRUCIAL TOOL
                DESPERATELY NEEDED IN THE CURRENT CRISIS.</p>
            <img className={classes.heart} src={heart} alt="Heart"/>
        </div>
    )
};

export default SolutionSection;