import React from 'react';
import Tick from "./Tick";
import {createUseStyles} from "react-jss";
import {darkBlue, darkRed, defaultHeadline, defaultText, dynamicFontWithLineHeight} from "../mixins";

const useStyles = createUseStyles({
    formSentContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px',
    },
    headline: {
        ...defaultHeadline,
        ...dynamicFontWithLineHeight(40, 44, 30, 33, 25, 27),
        color: darkBlue,
        marginTop: '40px'
    },
    text: {
        ...defaultText,
        color: darkBlue,
        textAlign: 'center'
    },
    headlineError: {
        ...defaultHeadline,
        color: darkRed,
        ...dynamicFontWithLineHeight(35, 38, 30, 33, 25, 27),
    }
});

const FormSentDisplay = ({shouldShow, isLoading, isError}) => {
    const classes = useStyles();
    if (isError) {
        return (
            <div className={classes.formSentContainer}>
                <div className={classes.headlineError}>
                    An error has occurred while sending the message
                </div>
            </div>
        )
    }
    if (shouldShow && !isError) {
        return (
            <div className={classes.formSentContainer}>
                <div className="form-sent-tick">
                    <Tick isLoading={isLoading}/>
                </div>
                <div className={classes.headline}>
                    thank you for showing your support
                </div>
                <div className={classes.text}>
                    You will receive conformation email shortly.
                </div>
            </div>
        );
    }
    return null;
};
export default FormSentDisplay;