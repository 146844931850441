import React from "react";
import {createUseStyles} from 'react-jss';
import map from '../images/Map.png';
import EuropeanCommissionLogo from "../images/EuropeanCommissionLogo.svg";
import ukc from "../images/UKCLogo.svg";
import inova from "../images/InovaLogo.svg";
import convit from "../images/CovitLogo.svg";
import googlePlay from "../images/google-play.svg";
import appStore from "../images/app-store.svg";
import {
    aboveBreakpoint,
    belowBreakpoint,
    breakpoint2, breakpoint3, breakpoint4, classNames, dynamicFontSize,
    lightBlue,
    SourceSansProBold,
    SourceSansProRegular, white
} from "../mixins";

const useStyles = createUseStyles({
    landing: {
        justifyContent: 'space-between',
        color: 'white',
        height: '100%',
        width: '100%',
        ...aboveBreakpoint(breakpoint4, {
            display: 'flex',
            justifyContent: 'space-between',
        }),
    },
    headline: {
        marginTop: '25%',
        fontFamily: SourceSansProBold,
        width: '513px',
        fontSize: '48px',
        lineHeight: '50px',
        ...belowBreakpoint(breakpoint2, {
            marginTop: '20%',
            width: 'unset',
            fontSize: '35px',
            lineHeight: '40px',
        }),
        ...belowBreakpoint(breakpoint4, {
            marginTop: '10%',
        }),
    },
    textBold: {
        fontFamily: SourceSansProBold,
        maxWidth: '431px',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '1px',
        marginBottom: '20px',
        ...belowBreakpoint(breakpoint2, {
            fontSize: '20px',
            lineHeight: '24px',
        }),
    },
    textNormal: {
        fontFamily: SourceSansProRegular,
        maxWidth: '440px',
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '0.5px',
        color: lightBlue,
        ...belowBreakpoint(breakpoint2, {
            fontSize: '15px',
            lineHeight: '18px',
        }),
        ...belowBreakpoint(breakpoint3, {
            fontSize: '12px',
            lineHeight: '15px',
        }),
    },
    mapWrapper: {
        ...aboveBreakpoint(breakpoint4, {
            display: 'grid',
            height: '100%',
        }),
    },
    map: {
        maxWidth: '100%',
        maxHeight: '100vh',
        margin: 'auto',
    },
    logo: {
        marginTop: '20px',
        height: '45px',
        marginRight: '30px',
    },
    coordinatedTex: {
        fontFamily: SourceSansProRegular,
        color: white,
        ...dynamicFontSize(14, 14, 10),
        lineHeight: '20px',
        letterSpacing: '0.5px',
        ...aboveBreakpoint(breakpoint2, {
            marginTop: '100px',
        }),
    },
    iconWrapper: {
        marginBottom: '15px',
    },
    icon: {
        marginRight: '30px',
        ...belowBreakpoint(breakpoint2, {
            height: '18px',
        })
    },
    iconBig: {
        ...belowBreakpoint(breakpoint2, {
            height: '28px',
        })
    },
    appDownloadIcon: {
        height: '40px',
        marginRight: '20px',
        marginTop: '40px',
        ...belowBreakpoint(breakpoint2, {
            marginTop: '20px',
            height: '25px',
        })
    },
    iconDisabled: {
        opacity: 0.5,
    }
});

const LandingSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.landing}>
            <div>
                <div>
                    <img className={classes.logo} src={convit} alt="CovIT Logo" />
                </div>
                <p className={classes.headline}>A GLOBAL E-HEALTH CROWDFUNDING INITIATIVE</p>
                <p className={classes.textBold}>FOR AN AFFORDABLE TELE-MEDICINE BASED DIAGNOSIS, THERAPY AND MONITORING OF
                    PATIENTS INFECTED WITH COVID-19</p>
                <p className={classes.textNormal}>AND REMOTE CARE FOR OTHER SPECIALTIES AND CHRONICAL CONDITIONS</p>
                <p className={classes.coordinatedTex}>Coordinated and supported by:</p>
                <div className={classes.iconWrapper}>
                    <a target={'_blank'} rel={'noopener noreferrer'} href={'https://www.ukc-mb.si/en/'}>
                        <img className={classes.icon} src={ukc} alt="UKC"/>
                    </a>
                    <a target={'_blank'} rel={'noopener noreferrer'} href={'https://www.inova.si/?lang=EN'}>
                        <img className={classes.icon} src={inova} alt="UKC"/>
                    </a>
                    <a target={'_blank'} rel={'noopener noreferrer'}
                       href={'https://ec.europa.eu/info/research-and-innovation/research-area/health-research-and-innovation/coronavirus-research_en'}>
                        <img className={classes.iconBig} src={EuropeanCommissionLogo} alt="European Commission"/>
                    </a>
                </div>
                <div>
                    <a target={'_blank'} rel={'noopener noreferrer'} href={'https://play.google.com/store/apps/details?id=si.inova.mymed'}>
                        <img className={classes.appDownloadIcon} src={googlePlay} alt="Get it on Google Play"/>
                    </a>
                    {/*<a target={'_blank'} rel={'noopener noreferrer'} href={''}>*/}
                        <img className={classNames(classes.appDownloadIcon, classes.iconDisabled)} src={appStore} alt="Download on the App Store"/>
                    {/*</a>*/}
                </div>
            </div>
            <div className={classes.mapWrapper}>
                <img src={map} className={classes.map} alt="map"/>
            </div>
        </div>
    )
};

export default LandingSection;
