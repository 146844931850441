import React from 'react';
import {createUseStyles} from 'react-jss';
import {
    aboveBreakpoint, breakpoint1,
    darkPink, defaultHeadline, defaultText, dynamicFontWithLineHeight, SourceSansProBold,
} from "../mixins";
import BoxText from "./BoxText";

const useStyles = createUseStyles({
    headline: {
        ...defaultHeadline,
        color: darkPink,
        marginBottom: '16px',
        textAlign: 'unset',
        ...dynamicFontWithLineHeight(40, 44, 30, 33, 25, 27),
    },
    text: {
        ...defaultText,
    },
    content: {
        height: '100%',
        width: '100%',
        ...aboveBreakpoint(breakpoint1, {
            marginRight: '312px',
        }),
    },
    bold: {
        fontFamily: SourceSansProBold,
    }
});

const ProblemSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.content}>
            <BoxText text={'problem'} isDark={true}/>
            <p className={classes.headline}>NATIONAL HEALTH SYSTEMS</p>
            <p className={classes.text}>
                OUTBREAK OF COVID-19 VIRUS has put our healthcare systems under a stress that has no precedents since
                the Second World War and we are <span className={classes.bold}> on the verge of collapse </span>– we were not prepared and equipped
                with systems which would be especially designed to handle similar crises from all the angles, including
                the exponential increase of the patients.
            </p>
            <p className={classes.headline}>WE ARE falling BEHIND ASIAN COUNTRIES AND ARE in NEED TO EFFECTIVELY RESHAPE
                EUROPE’S DIGITAL FUTURE IN THE HEALTH-SECTOR</p>
            <p className={classes.text}>
                As proven in Japan, South Korea, China and Singapore, the <span className={classes.bold}> technology was a real champion </span> in
                restraining the spread and fight against the virus.
            </p>
            <p className={classes.headline}>Pandemic profiteering already present in the current crisis</p>
            <p className={classes.text}>
                More than ever, we are TIRED of continuous various profiteering in the health-care sector, which is
                already present in the current epidemic - ranging from <span className={classes.bold}> masks, respirators, disinfectants and
                thousands of software related solutions with sky-high prices </span> and hidden costs with long term
                subscriptions – as usual in the health-sector.
            </p>
        </div>
    )
};

export default ProblemSection;