import React from "react";
import {createUseStyles} from 'react-jss';
import {
    aboveBreakpoint, belowBreakpoint,
    blue,
    breakpoint2,
    breakpoint4,
    defaultHeadline,
    dynamicFontSize,
    SourceSansProBold
} from "../mixins";
import RoundIconText from "./RoundIconText";
import IconCircleTemperature from '../images/IconCircleTemperature.svg';
import IconCircleRespiratory from '../images/IconCircleRespiratory.svg';
import IconCircleHr from '../images/IconCircleHr.svg';
import IconCircleBlood from '../images/IconCircleBloodsugar.svg';
import IconCircleBreathing from '../images/IconCircleBreathing.svg';
import IconCircleOxygen from '../images/IconCircleOxygen.svg';
import CommunicationExplanation from "../images/CommunicationExplanation.png";


const useStyles = createUseStyles({
    covidSection: {
        height: '100%',
        textAlign: 'center',
    },
    headlineCovit: {
        ...defaultHeadline,
        color: blue,
        ...aboveBreakpoint(breakpoint4, {
            marginBottom: '60px',
        }),
        ...aboveBreakpoint(breakpoint2, {
            marginTop: '100px',
        }),
    },
    text: {
        fontFamily: SourceSansProBold,
        ...dynamicFontSize(20, 18, 15),
        lineHeight: '30px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    iconsWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(6, auto)',
        justifyItems: 'center',
        ...belowBreakpoint(breakpoint2, {
            gridTemplateColumns: 'repeat(3, auto)',
        }),
        ...belowBreakpoint(breakpoint4, {
            gridTemplateColumns: 'repeat(2, auto)',
        }),
    },
    headlineCommunication: {
        ...defaultHeadline,
        color: blue,
        maxWidth: '900px',
        marginTop: '50px',
        marginBottom: '40px',
        ...aboveBreakpoint(breakpoint4, {
            marginTop: '100px',
            marginBottom: '60px',
        }),
        ...aboveBreakpoint(breakpoint2, {
            marginTop: '260px !important',
            marginBottom: '120px !important',
        }),

        marginLeft: 'auto',
        marginRight: 'auto',
    },
    img: {
        maxWidth: '100%',
        marginBottom: '30px',
        ...aboveBreakpoint(1000, {
            maxWidth: '692px',
            marginBottom: '60px'
        })
    },
});

const CovidSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.covidSection}>
            <p className={classes.headlineCovit}>Covid-19 - Initial Module of the covIT platform</p>
            <p className={classes.text}>Real-Time Health Monitoring</p>
            <div className={classes.iconsWrapper}>
                <RoundIconText text={'Body Temperature'} icon={IconCircleTemperature}/>
                <RoundIconText text={'Respiratory Rate'} icon={IconCircleBreathing}/>
                <RoundIconText text={'Heart Rate'} icon={IconCircleRespiratory}/>
                <RoundIconText text={'Blood Pressure'} icon={IconCircleHr}/>
                <RoundIconText text={'Blood Sugar'} icon={IconCircleBlood}/>
                <RoundIconText text={'Oxygen Saturation'} icon={IconCircleOxygen}/>
            </div>
            <p className={classes.text}>& Other Descriptive Symptoms</p>

            <p className={classes.headlineCommunication}>Two-way communication between patients and medical professionals</p>
            <img className={classes.img} src={CommunicationExplanation} alt="UKC"/>
        </div>
    )
};

export default CovidSection;