import React from "react";
import {createUseStyles} from 'react-jss';
import {
    aboveBreakpoint, breakpoint1,
    breakpoint2,
    breakpoint5,
    darkPink,
    defaultHeadline,
    defaultText,
    dynamicFontWithLineHeight, SourceSansProBold
} from "../mixins";
import road from "../images/Roadmap.svg";

const useStyles = createUseStyles({
    roadmapSection: {
        width: '100%',
        height: '100%',
    },
    headline: {
        ...defaultHeadline,
        color: darkPink,
        marginBottom: '16px',
        textAlign: 'unset',
        ...dynamicFontWithLineHeight(40, 44, 30, 33, 25, 27),
        marginTop: '0px',
    },
    text: {
        ...defaultText,
        ...aboveBreakpoint(breakpoint1, {
            marginRight: '312px',
        }),
    },
    imageWrapper: {
        width: '100%',
        textAlign: 'center',
        ...aboveBreakpoint(breakpoint5, {
            marginTop: '30px'
        }),
        ...aboveBreakpoint(breakpoint2, {
            marginTop: '60px'
        }),

    },
    roadImage: {
        maxWidth: '100%'
    },
    bold: {
        fontFamily: SourceSansProBold,
    }
});

const RoadMapSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.roadmapSection}>
            <p className={classes.headline}>Initiative and its expansion in 3 months</p>
            <p className={classes.text}>The initial set of participants - a few health institutions in <span className={classes.bold}> Slovenia,
                Austria and Italy </span> - quickly joined forces with other research institutes in these Countries,
                private companies in the IT sector and other specialities willing to join and contribute.</p>
            <p className={classes.headline}>our roadmap</p>
            <div className={classes.imageWrapper}>
                <img className={classes.roadImage} src={road} alt="Road map"/>
            </div>
        </div>
    )
};

export default RoadMapSection;