import React, {useState} from 'react';
import {createUseStyles} from "react-jss";
import {darkGray, dynamicFontWithLineHeight, SourceSansProBold, SourceSansProRegular} from "../mixins";
import Input from "./Input";
import closeIcon from "../images/Close.svg";
import Button from "./Button";
import FormSentDisplay from "./FormSentDisplay";
import axios from 'axios';

const url = 'https://int.radio3dot0.com:8443/covit-subscriptions/covit/subscriptions';

const useStyles = createUseStyles({
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modal: {
        maxWidth: '689px',
        maxHeight: '650px',
        backgroundColor: 'white',
        position: 'fixed',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
        animation: ' 0.2s fadein',
        animationTimingFunction: 'ease-in-out',
        marginRight: '100px'
    },
    content: {
        margin: '40px',
        marginBottom: '0',
    },
    headline: {
        fontFamily: SourceSansProBold,
        color: darkGray,
        lineHeight: '44px',
        ...dynamicFontWithLineHeight(40, 44, 35, 38, 25, 28),
        letterSpacing: '2px',
        textTransform: 'uppercase',
        marginTop: '0',
        marginBottom: '3px',
    },
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    close: {
        marginRight: '10px',
        cursor: 'pointer',
        marginLeft: '150px',
    },
    gdprText: {
        fontSize: '10px',
        margin: '5px',
        marginLeft: '40px',
        fontFamily: SourceSansProRegular,
    }
});

const ModalForm = ({close}) => {

    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [companyUrl, setCompanyUrl] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [invalid, setInvalid] = useState(false);

    const [showFormSent, setShowFormSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const disableButton = !name || name.trim() === '' || !address || address.trim() === '' || !email || email.trim() === '' || invalid;

    const sendForm = () => {
        setIsLoading(true);
        setShowFormSent(true);
        const formData = {
            "firstAndLastName": name,
            "company": company && company.trim() ? company : null,
            "companyUrl": companyUrl && companyUrl.trim() ? companyUrl : null,
            "email": email,
            "address": address,
        };
        axios.post(url, formData)
            .then(response => {
                setIsLoading(false);
            }).catch(error => {
            setIsLoading(false);
            setError(true);
        });
    };

    const classes = useStyles();
    return (
        <div className={classes.overlay}>
            <div className={classes.modal}>
                <div className={classes.content}>
                    <div className={classes.formHeader}>
                        <p style={{opacity: showFormSent ? '0' : '1'}} className={classes.headline}>show your
                            support</p>
                        <img className={classes.close} src={closeIcon} alt="Close Form" onClick={() => close()}/>
                    </div>
                    <FormSentDisplay isLoading={isLoading} shouldShow={showFormSent} isError={error}/>
                    {!showFormSent &&
                    <>
                        <Input label={'Name and Surname*'} type={'text'} value={name} setValue={setName}/>
                        <Input label={'Company'} type={'text'} value={company} setValue={setCompany}/>
                        <Input label={'Company URL'} type={'text'} value={companyUrl} setValue={setCompanyUrl}/>
                        <Input label={'Address*'} type={'text'} value={address} setValue={setAddress}/>
                        <Input label={'E-mail*'} type={'email'} value={email} setValue={setEmail} invalid={invalid}
                               setInvalid={setInvalid}/>
                        <Button text={'send'} action={sendForm} isRed={true} isDisabled={disableButton}/>
                    </>
                    }
                </div>
                {!showFormSent && <p className={classes.gdprText}>* Your data is being processed according to the GDPR principles</p>}
            </div>
        </div>
    )
};

export default ModalForm;