import React from "react";
import {createUseStyles} from 'react-jss';
import {belowBreakpoint, blue, breakpoint5, dynamicFontSize, SourceSansProRegular} from "../mixins";

const useStyles = createUseStyles({
    roundIcon: {
        margin: '12px',
        textAlign: 'center',
        maxWidth: '158px',
    },
    text: {
        fontFamily: SourceSansProRegular,
        fontWeight: '600',
        ...dynamicFontSize(20, 18, 18),
        lineHeight: '24px',
        marginTop: '10px',
        textAlign: 'center',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        color: blue,
        wordSpacing: '100px',
    },
    icon: {
        ...belowBreakpoint(breakpoint5, {
            width: '60px'
        }),
    }
});

const RoundIconText = ({text, icon}) => {
    const classes = useStyles();
    return (
        <div className={classes.roundIcon}>
            <img className={classes.icon} src={icon} alt={text}/>
            <p className={classes.text}>{text}</p>
        </div>
    )
};

export default RoundIconText;