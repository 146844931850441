import React from "react";
import {createUseStyles} from "react-jss";
import {
    belowBreakpoint,
    blue,
    breakpoint4,
    darkGray,
    gray,
    red,
    SourceSansProBold,
    SourceSansProRegular
} from "../mixins";

const useStyles = createUseStyles({
    inputField: {
        width: '-webkit-fill-available',
        marginTop: '51px',
        marginRight: '80px',
        ...belowBreakpoint(breakpoint4, {
            marginTop: '30px',
            marginRight: '0px',
        }),
        position: 'relative'
    },
    label: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        color: '#5C5C5C',
        transition: '0.2s all',
        cursor: 'text',
        fontFamily: SourceSansProRegular,
        letterSpacing: '0.5px',
        fontSize: '18px',
        textIndent: '16px',
    },
    input: {
        width: '100%',
        border: '0',
        outline: '0',
        padding: '4px 0',
        borderBottom: '1px solid ' + gray,
        boxShadow: 'none',
        color: darkGray,
        fontFamily: SourceSansProRegular,
        letterSpacing: '0.5px',
        fontSize: '18px',
        textIndent: '16px',
        '&:not(:placeholder-shown):not(:focus):valid~label': {
            top: '-16px',
            color: '#A1A1A1',
            textTransform: 'uppercase',
            fontFamily: SourceSansProBold,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '1px',
        },
        '&:invalid~label': {
            top: '-16px',
            color: '#A1A1A1',
            textTransform: 'uppercase',
            fontFamily: SourceSansProBold,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '1px',
        },
        '&:focus~label': {
            top: '-16px',
            color: blue,
            textTransform: 'uppercase',
            fontFamily: SourceSansProBold,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '1px',
        },
        '&:valid': {
            borderBottom: '1px solid ' + gray,
        },
        '&:invalid': {
            borderBottom: '1px solid ' + red,
        },
        '&:focus': {
            borderBottom: '1px solid ' + blue,
        },
    },
    alert: {
        marginBottom: '',
        marginTop: '4px',
        fontFamily: SourceSansProRegular,
        fontSize: '12px',
        lineHeight: '14px',
        letterSpacing: '0.25px',
        color: red,
        textIndent: '16px',
    }
});

const Input = ({label, type, value, setValue, invalid, setInvalid}) => {
    const classes = useStyles();
    const handleChange = event => {
        setValue(event.target.value);
    };
    const handleFocusOut = event => {
        if (setInvalid) setInvalid(!event.target.checkValidity());
    };
    return (
        <div className={classes.inputField}>
            <input placeholder={' '} id={label} className={classes.input} type={type} value={value}
                   onChange={handleChange} onBlur={handleFocusOut}/>
            <label htmlFor={label} className={classes.label}>{label}</label>
            {invalid && <p className={classes.alert}>Wrong e-mail format</p>}
        </div>
    )
};

export default Input;