import React from "react";
import {createUseStyles} from "react-jss";
import {belowBreakpoint, breakpoint4, classNames, red, SourceSansProBold, white} from "../mixins";

const useStyles = createUseStyles({
    button: {
        backgroundColor: white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '164px',
        height: '42px',
        borderRadius: '100px',
        marginTop: '30px',
        marginBottom: '46px',
        ...belowBreakpoint(breakpoint4, {
            marginBottom: '30px',
        }),
        cursor: 'pointer',
        transition: '0.3s',
    },
    buttonText: {
        fontFamily: SourceSansProBold,
        lineHeight: '18px',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        fontsize: '16px',
        color: red,
    },
    redButton: {
        marginTop: '55px',
        marginBottom: '30px',
        backgroundColor: red,
        width: '93px',
        height: '42px',
    },
    redButtonText: {
        color: white
    },
    disabled: {
        opacity: '0.2'
    }
});

const Button = ({action, text, isRed, isDisabled}) => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.button, isRed ? classes.redButton : '', isDisabled ? classes.disabled : '')}
             onClick={() => {
                 if (!isDisabled) action()
             }}>
            <p className={classNames(classes.buttonText, isRed ? classes.redButtonText : '')}>{text}</p>
        </div>
    )
};

export default Button;