import React from 'react';
import LandingSection from "./components/LandingSection";
import Section from "./components/Section";
import { createUseStyles } from 'react-jss';
import './App.css';
import {
    aboveBreakpoint,
    belowBreakpoint,
    blue, breakpoint2,
    breakpoint5,
    darkBlue,
    defaultPadding,
    lightGray,
    lightPink,
    red
} from "./mixins";
import ProblemSection from "./components/ProblemSection";
import SolutionSection from "./components/SolutionSection";
import InitiativeSection from "./components/InitiativeSection";
import HealthSection from "./components/HealthSection";
import CovidSection from "./components/CovidSection";
import RoadMapSection from "./components/RoadMapSection";
import SupportSection from "./components/SupportSection";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import covit20 from "./images/Covit20.jpg";

const useStyles = createUseStyles({
    landing: {
        backgroundColor: darkBlue,
    },
    landingPadding: {
        paddingLeft: defaultPadding,
        paddingTop: '35px',
        paddingBottom: '25px',
        paddingRight: defaultPadding,
        ...belowBreakpoint(breakpoint5, {
            paddingLeft: '30px',
            paddingRight: '30px',
        })
    },
    pink: {
        backgroundColor: lightPink,
    },
    gray: {
        backgroundColor: lightGray,
    },
    red: {
        backgroundColor: red,
    },
    blue: {
        backgroundColor: blue,
    }
});

function App() {
    const classes = useStyles();
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/covit20">
                        <img style={{width: "100%"}} src={covit20} alt="COVIT 2.0"/>
                    </Route>
                    <Route path="/">
                        <div>
                            <Section customStyle={classes.landing} customPadding={classes.landingPadding}>
                                <LandingSection />
                            </Section>
                            <Section>
                                <ProblemSection />
                            </Section>
                            <Section customStyle={classes.pink}>
                                <SolutionSection />
                            </Section>
                            <Section>
                                <InitiativeSection />
                            </Section>
                            <Section customStyle={classes.gray} ignoreLimit={true}>
                                <HealthSection />
                            </Section>
                            <Section customStyle={classes.gray}>
                                <CovidSection />
                            </Section>
                            <Section>
                                <RoadMapSection />
                            </Section>
                            <Section customStyle={classes.red} ignoreLimit={true}>
                                <SupportSection />
                            </Section>
                        </div>
                    </Route>
                </Switch>
            </div>
        </Router>
        
      );
}

export default App;
