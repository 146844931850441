import React from 'react';
import {createUseStyles} from 'react-jss';
import {aboveBreakpoint, belowBreakpoint, breakpoint1, breakpoint2, breakpoint5, classNames} from "../mixins";

const useStyles = createUseStyles({
    section: {
        ...aboveBreakpoint(breakpoint2, {
            minHeight: '100vh',
        }),
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        maxWidth: '1440px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...aboveBreakpoint(breakpoint1, {
            width: '1440px',
        }),
    },
    padding: {
        padding: '80px',
        ...belowBreakpoint(breakpoint5, {
            padding: '30px',
        })
    }
});

const Section = ({children, customStyle, customPadding, ignoreLimit}) => {
    const classes = useStyles();
    if (ignoreLimit) {
        return (
            <div className={classNames(classes.section, customStyle)}>
                {children}
            </div>
        )
    }
    return (
        <div className={classNames(classes.section, customStyle)}>
            <div className={classNames(classes.content, customPadding ? customPadding : classes.padding)}>
                {children}
            </div>
        </div>
    )
};

export default Section;