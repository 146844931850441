import React from "react";
import {createUseStyles} from 'react-jss';
import BoxText from "./BoxText";
import {
    aboveBreakpoint, breakpoint1,
    breakpoint4, classNames,
    darkPink,
    defaultText, SourceSansProBold,
} from "../mixins";

const useStyles = createUseStyles({
    initiativeSection: {
        height: '100%',
        width: '100%',
        paddingTop: '50px',
        ...aboveBreakpoint(breakpoint1, {
            marginRight: '312px',
        }),
    },
    text: {
        ...defaultText,
    },
    margin: {
        ...aboveBreakpoint(breakpoint4, {
            marginBottom: '70px',
        }),
    },
    colorText: {
        color: darkPink,
        fontWeight: 'bold',
        fontFamily: SourceSansProBold,
    },
    bold: {
        fontFamily: SourceSansProBold,
    }
});

const InitiativeSection = () => {
    const classes = useStyles();
    return (
        <div className={classes.initiativeSection}>
            <BoxText text={'our initiative'}/>
            <p className={classNames(classes.text, classes.margin)}>Is to design and develop an <span className={classes.bold}> AFFORDABLE REMOTE
                TELE-MEDICINE BASED DIAGNOSIS, THERAPY AND MONITORING PLATFORM FOR PATIENTS INFECTED WITH
                COVID-19 </span> which can be later used for various other tele-medicine related purposes.</p>
            <BoxText text={'the people'}/>
            <p className={classes.text}>We are a group of thousands of <span className={classes.bold}> doctors, nurses and other medical
                practitioners </span> working in the scope of several national Health Associations and Institutes in Europe
                - coordinated by Maribor University Medical Centre, collaborating with the Italian partners in the core
                of the epidemic.</p>
            <p className={classes.text}><span className={classes.colorText}>Our main goal is to join our forces, our knowledge, our networks and our technologies </span> in
                order to provide the same effective care for all the patients, regardless of wealth and sophistication
                of their national healthcare systems.</p>
        </div>
    )
};

export default InitiativeSection;
