import React from "react";
import {createUseStyles} from 'react-jss';
import {classNames, darkGray, darkPink, dynamicFontSize, SourceSansProBold, white} from "../mixins";

const useStyles = createUseStyles({
    box: {
        backgroundColor: darkPink,
        width: 'fit-content',
        padding: '5px 16px',
    },
    text: {
        fontFamily: SourceSansProBold,
        fontSize: '26px',
        textTransform: 'uppercase',
        color: white,
        margin: '0px',
        ...dynamicFontSize(26, 20, 18),
    },
    dark: {
        backgroundColor: darkGray
    }

});

const BoxText = ({text, isDark}) => {
    const classes = useStyles();
    return (
        <div className={classNames(classes.box, isDark ? classes.dark : '')}>
            <p className={classes.text}>{text}</p>
        </div>
    )
};

export default BoxText;