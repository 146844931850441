export const darkBlue = '#243353';
export const blue = '#274C96';
export const lightBlue = '#AEBAD2';

export const lightPink = '#FDEFEF';
export const darkPink = '#EE524C';
export const red = '#EE524C';
export const darkRed = '#BC3C38';

export const white = '#FFFFFF';
export const darkGray = '#121B2E';
export const gray = '#CCCBCB';
export const lightGray = '#E2E5EC';


export const SourceSansProBold = 'SourceSansPro-Bold, Arial';
export const SourceSansProSemiBold = 'SourceSansPro-SemiBold, Arial';
export const SourceSansProRegular = 'SourceSansPro-Regular, Arial';

export const breakpoint1 = 1440;
export const breakpoint2 = 1200;
export const breakpoint3 = 1000;
export const breakpoint4 = 786;
export const breakpoint5 = 576;

export function classNames() {
    return Array.prototype.slice.call(arguments).join(" ");
}

export const belowBreakpoint = (breakpoint, content) => ({
    [`@media only screen and (max-width: ${breakpoint - 1}px)`]: content,
});

export const aboveBreakpoint = (breakpoint, content) => ({
    [`@media only screen and (min-width: ${breakpoint - 1}px)`]: content,
});

export const dynamicFontSize = (xl, md, s) => ({
    fontSize: xl+'px',
    ...belowBreakpoint(breakpoint2, {
        fontSize: md+'px',
    }),
    ...belowBreakpoint(breakpoint4, {
        fontSize: s+'px',
    }),
});

export const dynamicFontWithLineHeight = (xl, lxl, md, lmd, s, ls) => ({
    fontSize: xl+'px',
    lineHeight: lxl + 'px',
    ...belowBreakpoint(breakpoint2, {
        fontSize: md+'px',
        lineHeight: lmd + 'px',
    }),
    ...belowBreakpoint(breakpoint4, {
        fontSize: s+'px',
        lineHeight: ls + 'px',
    }),
});

export const defaultPadding = '80px';

export const defaultText = {
    fontFamily: SourceSansProRegular,
    marginTop: '24px',
    lineHeight: '30px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    ...dynamicFontSize(20, 20, 20),
    ...belowBreakpoint(breakpoint2, {
        letterSpacing: '0.5px',
        lineHeight: '25px',
    }),
};

export const defaultHeadline = {
    fontFamily: SourceSansProBold,
    fontSize: '48px',
    ...dynamicFontSize(48, 35, 25),
    ...aboveBreakpoint(breakpoint2, {
        lineHeight: '50px',
    }),
    color: darkGray,
    textAlign: 'center',
    textTransform: 'uppercase',
};